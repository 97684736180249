import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'

interface ButtonProps {
  text: string
  icon: string
  href?: string
  height: number
  width: number
}

function Button(props: ButtonProps) {
  return (
    <Link
      href={props.href || '#'}
      className="flex md:flex-col justify-start items-center / w-full md:w-18pct lg:w-44 / mb-3 lg:mx-2 / py-1 md:py-3 lg:py-5 / 
       text-center text-primary font-bold / rounded cursor-pointer border border-white bg-white shadow-lg / 
       hover:text-primary-bright hover:shadow-xl hover:border-primary-bright hover:bg-primary-light-bg-2 / transition-all"
    >
      <div className="w-12 lg:w-14 / md:mb-1.5 lg:mb-2 / ml-6 mr-4">
        <Image
          src={props.icon}
          alt={`${props.text} icon`}
          role="presentation"
          height={props.height}
          width={props.width}
          style={nextImageHelper.intrinsic}
        />
      </div>
      <span className="text-lg md:text-sm lg:text-base">{props.text}</span>
    </Link>
  )
}
export function HeroCategoryTiles() {
  return (
    <div className="c-hero / -mt-16 lg:-mt-20 / pb-12 sm:pb-16 lg:pb-18 pt-20 sm:pt-28 lg:pt-32 / relative overflow-hidden bg-gradient-blue-green-270">
      <div className="c-hero__content / container-fluid relative">
        <div className="relative z-10 / flex flex-col items-center justify-center / mb-8 mx-auto / xl:px-4">
          <h1 className="c-hero__title / mb-4 lg:mb-5 / text-center lg:text-left text-primary font-display font-bold text-4xl md:text-5xl">
            No Games.{` `}
            <span className="block sm:inline-block">
              Just Comparisons.<sup className="relative font-sans">&trade;</sup>
            </span>
          </h1>
          <hr className="border-3 w-24 md:w-32 border-tetriary / mb-4 lg:mb-5 mx-auto" />
          <p className="max-w-md md:max-w-lg mb-5 sm:mb-6 md:mb-8 / mx-auto lg:mx-0 / text-center text-slate-700 sm:text-lg lg:text-xl">
            Explore our most popular card categories to find credit cards with
            the benefits you&apos;re looking&nbsp;for.
          </p>
          <div className="flex flex-col md:flex-row md:justify-around lg:justify-center / w-full max-w-sm md:max-w-none / mx-auto">
            {[
              {
                text: 'Top Credit Cards',
                href: '/credit-cards/best',
                icon: '/static/icons/categories/top-cc.svg',
                height: 64,
                width: 64,
              },
              {
                text: 'Balance Transfer',
                href: '/credit-cards/best/balance-transfer',
                icon: '/static/icons/categories/balance-transfer.svg',
                height: 64,
                width: 64,
              },
              {
                text: 'Low Interest',
                href: '/credit-cards/best/low-interest',
                icon: '/static/icons/categories/low-interest.svg',
                height: 64,
                width: 64,
              },
              {
                text: 'Rewards',
                href: '/credit-cards/best/rewards',
                icon: '/static/icons/categories/rewards.svg',
                height: 64,
                width: 64,
              },
              {
                text: 'Cash Back',
                href: '/credit-cards/best/cash-back',
                icon: '/static/icons/categories/cash-back.svg',
                height: 64,
                width: 64,
              },
            ].map((button, i) => (
              <Button key={i} {...button} />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center / mx-auto">
          <div className="mb-2 / text-center font-semibold text-slate-400 tracking-wider text-sm uppercase">
            As Seen In
          </div>
          <div className="flex flex-row justify-around items-center / w-full lg:max-w-2xl xl:max-w-3xl / opacity-25">
            <div className="custom-static-width-pbs / w-20">
              <Image
                src="/static/as-seen-on/pbs.svg"
                alt="PBS logo"
                height={48}
                width={106}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="w-22">
              <Image
                src="/static/as-seen-on/forbes-black.svg"
                alt="Forbes logo"
                height={24}
                width={88}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>

            <div className="hidden sm:block / lg:height-auto / w-14">
              <Image
                src="/static/as-seen-on/wsj-mark.svg"
                alt="Wall Street Journal logo"
                height={24}
                width={192}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="custom-static-width-usa-today / w-28">
              <Image
                src="/static/as-seen-on/usa-today-black.svg"
                alt="Usa Today logo"
                height={26}
                width={141}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <div className="w-12 / hidden sm:block">
              <Image
                src="/static/as-seen-on/tech-crunch-black.svg"
                alt="Tech Crunch logo"
                height={24}
                width={48}
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="c-hero__bot / absolute bottom-0 / bg-no-repeat / w-full">
        <div className="lg:hidden">
          <Image
            src="/static/images/bg-gradient-bot-sm.png"
            className="c-hero__img-bg-curve"
            alt="wave image mask"
            role="presentation"
            height={132}
            width={1536}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
        <div className="hidden lg:block">
          <Image
            src="/static/images/bg-bot-mask-white-lg.png"
            className="c-hero__img-bg-curve"
            alt="wave image mask"
            height={96}
            width={1383}
            style={nextImageHelper.responsive}
            sizes="100vw"
          />
        </div>
      </div>
      <style jsx>{`
        .c-hero {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }

        .c-hero__img-bg-curve {
          width: 150%;
          max-width: none;
          height: 3rem;
        }
        @media (min-width: 640px) {
          .c-hero__img-bg-curve {
            height: 2.5rem;
          }
        }
        @media (min-width: 1024px) {
          .c-hero__img-bg-curve {
            height: 3rem;
          }
        }
        @media (min-width: 1280px) {
          .c-hero__img-bg-curve {
            height: 5rem;
          }
        }
        .c-hero__title {
          line-height: 1.15;
        }
        .c-hero__title sup {
          top: -10px;
          font-size: 60%;
        }
        @media (min-width: 1152px) {
          .c-hero__title {
            font-size: 3.25rem;
          }
        }
        .c-hero__content {
          z-index: 2;
        }
        .c-hero__bot {
          z-index: 1;
        }
      `}</style>
    </div>
  )
}
