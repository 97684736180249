import React from 'react'

export interface IAwardsBadgeContext {
  awardsCardsSlugList?: Record<string, string>
  tipLabels?: string[]
}

export const AwardsBadgeContext = React.createContext<IAwardsBadgeContext>({
  awardsCardsSlugList: undefined,
  tipLabels: undefined,
})

export const AwardsBadgeContextProvider: React.FC<
  React.PropsWithChildren & { value: IAwardsBadgeContext }
> = (props) => <AwardsBadgeContext.Provider {...props} />

export const useAwardsBadgeContext = (): IAwardsBadgeContext => {
  const awardsBadgeData = React.useContext(AwardsBadgeContext)

  return awardsBadgeData
}
