import { useStatsigClient } from '@statsig/react-bindings'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { v4 as getUuid } from 'uuid'

import { viewProductList } from 'clients/segment'
import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'
import { getSortedAndFilteredBySortPosition } from 'utils/sort/sortPosition'
import { useExperiment } from 'utils/use-experiment-wrapper'
import { useIsPinningActive } from 'utils/useIsPinningActive'
import { useIsUserOnBrave } from 'utils/useIsUserOnBrave'
import type {
  CompareCredit,
  ProductDisplayType,
} from '../../../../types/compare-credit'
import { CardPanel, Modal, UserContext } from '../../'
import ErrorBoundary from '../../error-boundary'
import { Card } from './card'
import { LoadingSkeleton } from './loading-skeleton'

type AugmentedCardTip = CompareCredit.CardTip & {
  referencedCard: CompareCredit.Entity
  cardSlug: string
  cardIssuer: string
  linkText?: string
  sortPosition: number
  cardSortContext?: string
}

const TipCardTileDefault: React.FC<{
  tip: AugmentedCardTip
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entity>
  tag: CompareCredit.CategoryTag
  categoryTag: CompareCredit.CategoryTag
  index: number
  recommendedCredit?: boolean
  modalTriggerPosition?: number
  exitModalRef?: React.ComponentProps<typeof Card>['exitModalRef']
  productDisplay?: ProductDisplayType
  arrangementId?: string
}> = ({
  tip,
  cardOrderIds,
  referencedCards,
  tag,
  categoryTag,
  index,
  recommendedCredit,
  modalTriggerPosition,
  exitModalRef,
  productDisplay,
  arrangementId,
}) => {
  const { clientLocation } = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalCard, setModalCard] =
    useState<CompareCredit.FormattedCard | null>(null)

  const externalId = tag ? tag.id : '00'
  const categoryId = categoryTag ? categoryTag.id : '00'

  const { cardSlug } = tip

  const { referencedCard, ...restTip } = tip

  const orderId = useMemo<string>(
    () => cardOrderIds[cardSlug] || getUuid(),
    [cardOrderIds, cardSlug],
  )

  const position = index + 1

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <>
          {modalCard ? (
            <CardPanel
              categoryId={categoryId}
              externalId={externalId}
              onClose={() => setModalOpen(false)}
              view="modal"
              featured={false}
              entity={modalCard}
              comparisonDisabled={true}
              propsOrderId={orderId}
              arrangementId={arrangementId}
            />
          ) : null}
        </>
      </Modal>
      <div className="c-modal z-50 / hidden">
        <style jsx>{`
          .c-modal {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(2, 22, 45, 0.8);
          }
        `}</style>
      </div>
      <ErrorBoundary key={tip._key}>
        {productDisplay === 'panel' ? (
          <CardPanel
            categoryId={categoryId}
            externalId={externalId}
            index={index}
            position={position}
            /**
             * @todo fix type error below rather than mask with an assertion
             */
            entity={
              tip.referencedCard as
                | CompareCredit.FormattedCard
                | CompareCredit.NonPaidCard
            }
            view="list"
            collapsed
            sortable
            arrangementId={arrangementId}
          />
        ) : (
          <Card
            exitModalRef={exitModalRef}
            clientLocation={clientLocation}
            modalOpen={modalOpen}
            setModalCard={setModalCard}
            setModalOpen={setModalOpen}
            tag={tag}
            categoryTag={categoryTag}
            tip={restTip}
            card={referencedCard}
            cardOrderIds={cardOrderIds}
            referencedCards={referencedCards}
            position={position}
            modalTriggerPosition={modalTriggerPosition}
            recommendedCredit={recommendedCredit}
            arrangementId={arrangementId}
          />
        )}
      </ErrorBoundary>
    </>
  )
}

const getIssuer = (card: CompareCredit.Entity): string => {
  const { _type } = card

  switch (_type) {
    case 'card':
    case 'nonPaidCard':
      return card.issuer.slug.current
    case 'product':
      return card.brand.slug.current
  }
}

export const TipCardTileList = ({
  cardOrderIds,
  exitModalRef,
  exitModalMultiCardTipPosition,
}: {
  cardOrderIds: Record<string, string>
  exitModalRef?: React.ComponentProps<typeof Card>['exitModalRef']
  exitModalMultiCardTipPosition?: number
}) => {
  const TipCardTileListRender: React.FC<{
    value: {
      cardTips: CompareCredit.CardTip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      style: string
      sort: string
      pinnedCardTips: CompareCredit.PinnedProduct[]
      recommendedCredit?: boolean
      exitModalRef?: React.ComponentProps<typeof Card>['exitModalRef']
      exitModalMultiCardTipPosition?: number
      productDisplay?: ProductDisplayType
      referencedCards: null | Record<string, CompareCredit.Entity>
      listClassName?: string
    }
  }> = ({
    value: {
      cardTips,
      tag,
      categoryTag,
      sort,
      pinnedCardTips,
      recommendedCredit,
      productDisplay,
      referencedCards,
      listClassName,
    },
  }) => {
    const statsigClient = useStatsigClient()

    const isPinningActive = useIsPinningActive(sort)

    const userIsOnBrave = useIsUserOnBrave()

    /* ===== 2025.02: EXP-358 Chase Freedom Unlimited on Social (w/ browser Kickout) ===== */
    const { experiment: configExp358, isLoading: isLoadingExp358 } =
      useExperiment('exp_358_chase_fu_on_social_with_browser_kickout')
    const variationExp358 = configExp358?.value?.name || 'control'
    const isInExp358 = statsigClient.checkGate(
      'exp_358_chase_fu_on_social_with_browser_kickout_gate',
    )

    const shouldShowChaseFu = useMemo(() => {
      return (
        !isLoadingExp358 &&
        (isDesktop ||
          (variationExp358 === 'v1-chase-fu-with-kickout-experience' &&
            isInExp358))
      )
    }, [isLoadingExp358, isDesktop, variationExp358, isInExp358])

    const cardsData: AugmentedCardTip[] = useMemo(() => {
      const augmentedCardTips = cardTips.flatMap((cardTip) => {
        const referencedCard = referencedCards?.[cardTip.card.slug]

        if (referencedCard) {
          const cardSlug = referencedCard.slug

          const shouldShowCard =
            cardSlug === 'freedom-unlimited' ? shouldShowChaseFu : true

          if (shouldShowCard) {
            return [
              {
                ...cardTip,
                referencedCard: referencedCard,
                cardSlug: referencedCard.slug,
                cardIssuer: getIssuer(referencedCard),
                linkText:
                  'linkText' in referencedCard
                    ? referencedCard.linkText
                    : undefined,
                sortPosition:
                  'sortPosition' in referencedCard
                    ? referencedCard.sortPosition
                    : undefined,
                cardSortContext:
                  referencedCard._type === 'card' ||
                  referencedCard._type === 'product'
                    ? referencedCard.context?.sort
                    : undefined,
              },
            ]
          }
        }
        return []
      })

      const cardTipsSortedAndFiltered =
        getSortedAndFilteredBySortPosition(augmentedCardTips)

      const withBofaExcludedOnBrave = userIsOnBrave
        ? cardTipsSortedAndFiltered.flatMap((cardTip) =>
            cardTip.cardIssuer === 'bank-of-america' ? [] : [cardTip],
          )
        : cardTipsSortedAndFiltered

      const cardTipsWithPinsAppliedIfActive = isPinningActive
        ? pinEntities(
            withBofaExcludedOnBrave,
            formatPinnedProducts(
              pinnedCardTips?.map((pinnedCard) => {
                return {
                  card: {
                    _id: pinnedCard.card._id,
                    slug: pinnedCard.card.slug,
                  },
                  frequencyPercent: pinnedCard.frequencyPercent,
                }
              }) ?? [],
            ),
            mapEntityPositions(cardTips.map(({ card: { slug } }) => slug)),
          )
        : withBofaExcludedOnBrave

      return cardTipsWithPinsAppliedIfActive
    }, [
      cardTips,
      referencedCards,
      sort,
      userIsOnBrave,
      isPinningActive,
      pinnedCardTips,
    ])

    const arrangementId = useMemo(() => getUuid(), [cardsData])

    /**
     * Fire 'Product List Viewed' Segment event.
     */
    useEffect(() => {
      if (cardsData.length > 0) {
        const cardsInOrder = cardsData.map(
          ({ referencedCard }) => referencedCard,
        )

        viewProductList({
          entities: cardsInOrder,
          category: `tip:${tag.id}`,
          arrangementId: arrangementId,
        })
      }
    }, [cardsData, arrangementId])

    /**
     * Number representing the 1-indexed position at which the exit modal will
     * trigger, or undefined if the exit modal should not trigger.
     *
     * For now we want this to be a mobile trigger only for multi-card lists.
     */
    const modalTriggerPosition = isMobile
      ? cardsData.length < (exitModalMultiCardTipPosition || 0)
        ? cardsData.length
        : exitModalMultiCardTipPosition
      : undefined

    return (
      <ErrorBoundary>
        <div
          className={`c-card-tile-list__container / mb-4 ${
            listClassName ? listClassName : ''
          }`}
        >
          {cardsData.length > 0 ? (
            cardsData.map((cardData, index: number) => {
              return (
                <TipCardTileDefault
                  key={cardData._key}
                  referencedCards={referencedCards}
                  cardOrderIds={cardOrderIds}
                  tip={cardData}
                  tag={tag}
                  categoryTag={categoryTag}
                  index={index}
                  modalTriggerPosition={modalTriggerPosition}
                  recommendedCredit={recommendedCredit}
                  exitModalRef={exitModalRef}
                  productDisplay={productDisplay}
                  arrangementId={arrangementId}
                />
              )
            })
          ) : (
            <LoadingSkeleton n={4} />
          )}
        </div>
      </ErrorBoundary>
    )
  }

  return TipCardTileListRender
}
