import React from 'react'
import fetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'
import isEmpty from 'lodash/fp/isEmpty'
import isNil from 'lodash/fp/isNil'
import App from 'next/app'
import { DM_Serif_Display, Inria_Serif, Open_Sans } from 'next/font/google'
import Head from 'next/head'
import Router, { withRouter } from 'next/router'
import Script from 'next/script'
import { StatsigClient, StatsigOptions, StatsigUser } from '@statsig/js-client'
import { StatsigProvider } from '@statsig/react-bindings'
import 'url-search-params-polyfill'
import * as Sentry from '@sentry/nextjs'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'

import { CompareCredit } from '../../types'
import { getSessionInfo } from '../clients/gtag'
import { addProductToCart, removeProductFromCart } from '../clients/segment'
import {
  ModalLeaveBehindContextProvider,
  SortSelectContextProvider,
  UserContext,
} from '../components'
import { Segment, checkSample } from '../components/segment'
import { UID_COOKIE } from '../lib/constants'
import '../polyfills/from-entries-pollyfill'
import '../polyfills/promise-all-settled-polyfill'
import { ls } from '../utils'
import { checkErrorInstance } from '../utils/check-error-instance'
import getAllEntitiesSlugs from '../utils/get-all-entities-slugs'
import { getUrl } from '../utils/getURL'
import getUserAgentInfo from '../utils/getUserAgentInfo'

import '../style.css'

const InriaSerif = Inria_Serif({
  weight: ['400', '700'],
  display: 'swap',
  style: ['normal'],
  variable: '--font-inria-serif',
  subsets: ['latin', 'latin-ext'],
})

const OpenSans = Open_Sans({
  weight: ['400', '600', '700', '800'],
  style: ['normal'],
  display: 'swap',
  variable: '--font-open-sans',
  subsets: ['latin', 'latin-ext'],
})

const DmSerif = DM_Serif_Display({
  weight: ['400'],
  style: ['normal'],
  display: 'swap',
  variable: '--font-dm-serif-display',
  subsets: ['latin', 'latin-ext'],
})

const legalPages = [
  '/privacy/',
  '/terms-conditions/',
  '/partners/',
  '/ratings/',
  '/e-sign-agreement/',
  '/dividend-terms/',
  '/credit-authorization-agreement/',
  '/awards-methodology/',
]

class MyApp extends App {
  state: CompareCredit.AppState = {
    cards: [],
    comparisonCartCategoryBanner: [],
    clientLocation: { city: '', country: '', state: '', status: '' },
    gaSessionInfo: { ga_session_id: '', ga_session_number: '' },
    cartVersion: '2.2',
    cartCategoryBannerVersion: '1.0',
    recommendedCard: null,
    anonymousId: Cookies.get(UID_COOKIE) || null,
    navUpsellCard: null,
    url: {
      entry: getUrl(),
      current: {
        pathname: getUrl().pathname,
        query: getUrl().query,
        search: getUrl().search,
      },
    },
    statsigClient: {} as StatsigClient,
    statsigClientIsLoading: true,
  }

  static async getInitialProps({ Component, ctx }: any) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope: any) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      const err = checkErrorInstance(error)

      Sentry.captureException(err)
    })
  }

  componentDidMount = () => {
    try {
      this.checkAnonymousID()
      this.registerPageViewOnRouteChange()
      this.getRecommendedCard()
      this.getComparisonCartIdsAndUpdateCardData()
      this.getComparisonCartCategoryBannerIdsAndUpdateCardData()
      this.getGaSessionInfo()
      this.getLocation()
      this.getNavUpsellCard()
    } catch (error) {
      const err = checkErrorInstance(error)

      Sentry.captureException(err)
    }
  }

  checkAnonymousID = () => {
    const isInSample = checkSample(5)

    if (
      !this.state.anonymousId &&
      typeof window !== 'undefined' &&
      isInSample
    ) {
      Sentry.captureException(new Error('Anonymous ID Cookie Not Found!'))
    }
  }

  registerPageViewOnRouteChange() {
    this.props.router.events.on('routeChangeStart', (url: string) => {
      const newUrl = new URL(`${window.location.origin}${url}`)
      const updatedState = {
        url: { ...this.state.url, current: { pathname: newUrl.pathname } },
      }
      this.setState(updatedState)
    })
    this.props.router.events.on('routeChangeComplete', (url: string) => {
      const isInternationalUser = ls.getItem('isInternationalUser')
      if (isInternationalUser === 'true' && url !== '/international/') {
        this.props.router.push('/international')
      }
      // @ts-ignore
      if (window._tfa) {
        // @ts-ignore
        window._tfa.push({
          notify: 'event',
          name: 'page_view',
          'item-url': url,
        })
      }

      try {
        const utms = ['?utm', '&utm']
        const hasNoUTMParam = !utms.find(
          (str) => window?.location?.search.indexOf(str) !== -1,
        )
        const ga_client_id = Cookies.get('_ga') || null

        this.initializeStatsig()
        if (!url.includes('/secure') && hasNoUTMParam) {
          window.analytics.page(url, {
            pixel_ratio: window.devicePixelRatio,
            screen_height: window.screen.height,
            screen_width: window.screen.width,
            tz_offset: new Date().getTimezoneOffset(),
            userAgent: getUserAgentInfo(),
            ga_client_id,
          })
        }
      } catch (e) {
        const err = checkErrorInstance(e)

        Sentry.captureException(err)
      }
    })
  }

  getRecommendedCard() {
    fetch('/api/get-recommended-card')
      .then((res) => this.handleErrors(res))
      .then((res) => res.json())
      .then((res) => {
        this.setState({ recommendedCard: res.recommendedCard || null })
      })
      .catch((err) => {
        console.log('Error getting Recommended Card', err)
      })
  }

  async getNavUpsellCard() {
    fetch('/api/get-nav-upsell-card')
      .then((res) => this.handleErrors(res))
      .then((res) => res.json())
      .then((res) => {
        const navUpsellCardExists = !(res && Object.keys(res).length === 0)
        if (navUpsellCardExists) {
          const { navUpsellCard } = res
          getAllEntitiesSlugs([navUpsellCard.slug]).then((entities) => {
            if (entities && !entities.error) {
              const { cards } = entities
              const [card] = cards
              this.setState({ navUpsellCard: card })
            }
          })
        }
      })
      .catch((error) => {
        const err = checkErrorInstance(error)

        Sentry.captureException(err)
        console.log('Error getting Nav Upsell Card', err)
      })
  }

  async getComparisonCartIdsAndUpdateCardData() {
    const lsComparisonCart = ls.getItem(
      'comparisonCart',
      ['version'],
      this.state.cartVersion,
    )
    if (lsComparisonCart && lsComparisonCart.cardIds.length > 0) {
      const entities = await getAllEntitiesSlugs(lsComparisonCart.cardIds)
      if (entities && !entities.error) {
        this.setState({ cards: entities.cards })
      } else {
        console.log('error getting entities')
      }
    }
  }

  async getComparisonCartCategoryBannerIdsAndUpdateCardData() {
    const lsComparisonCart = ls.getItem(
      'comparisonCartCategoryBanner',
      ['version'],
      this.state.cartCategoryBannerVersion,
    )
    if (lsComparisonCart && lsComparisonCart.cardIds.length > 0) {
      const entities = await getAllEntitiesSlugs(lsComparisonCart.cardIds)
      if (entities && !entities.error) {
        this.setState({ comparisonCartCategoryBanner: entities.cards })
      } else {
        console.log('error getting entities')
      }
    }
  }

  async getGaSessionInfo() {
    const data = await getSessionInfo()
    this.setState({
      gaSessionInfo: {
        ga_session_id: data.ga_session_id,
        ga_session_number: data.ga_session_number,
      },
    })
  }

  async initializeStatsig() {
    const userID = this.getUserId()

    const user: StatsigUser = {
      userID,
      customIDs: { segmentAnonymousID: userID || '' },
      custom: {
        entryPathname: this.state?.url?.entry?.pathname || undefined,
        pathname: this.state?.url?.current?.pathname || undefined,
        locationState: this.state?.clientLocation?.state || undefined,
        ...this.state?.url?.entry?.query,
      },
    }

    const options: StatsigOptions = {
      environment: { tier: process.env.DEPLOYMENT_TARGET },
    }

    const statsigClient = new StatsigClient(
      process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY || '',
      user,
      options,
    )

    await statsigClient
      .initializeAsync()
      .then(() => {
        this.setState({ statsigClient, statsigClientIsLoading: false })
      })
      .catch((err) => Sentry.captureException(err))
  }

  getLocation() {
    fetch('/api/get-location')
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        } else {
          const err = new Error('Client location not found')
          throw err
        }
      })
      .then((res) => {
        const redirectToInternational =
          ((res.exp_266_remove_intl_filter &&
            !['US', 'CA', 'GB'].includes(res.country)) ||
            (!res.exp_266_remove_intl_filter && res.country !== 'US')) &&
          !isNil(res.country) &&
          !isEmpty(res.country)

        // Used later to redirect interntional users during route changes
        ls.setItem('isInternationalUser', redirectToInternational)

        // Do not redirect to /international if on a Legal page
        const urlPath = new URL(window.location.href).pathname
        const isOnLegalPage = legalPages.includes(urlPath)

        if (redirectToInternational && !isOnLegalPage) {
          Sentry.captureEvent({
            level: 'info',
            message: 'Client routed to /international',
            extra: {
              country: res.country,
              state: res.state,
            },
          })
          Router.push('/international')
        }
        this.setState(
          {
            clientLocation: {
              city: res.city,
              country: res.country,
              state: res.state,
              status: 'done',
            },
          },
          () => {
            this.initializeStatsig()
          },
        )
      })
      .catch((error) => {
        const isInSample = checkSample(5)
        const isProd = process.env.DEPLOYMENT_TARGET === 'production'

        this.setState(
          {
            clientLocation: {
              ...this.state.clientLocation,
              status: 'done',
            },
          },
          () => {
            this.initializeStatsig()
          },
        )

        if (isInSample) {
          const err = checkErrorInstance(error)
          const locationError = 'Client location not found'

          if (err.message === locationError && !isProd) {
            console.log('Client location not found')
          }
          // Pausing sending to Sentry temporarily. Need to see if this is helpful...
          if (err.message !== locationError) {
            Sentry.captureException(err)
          }
        }
      })
  }

  handleErrors(response: any) {
    if (!response.ok) {
      const err = new Error(response.statusText)
      Sentry.captureException(err, {
        contexts: {
          error: {
            status: response.status,
            url: response.url,
          },
        },
      })
      throw err
    }
    return response
  }

  // if state updates, save new cardIds to localStorage
  componentDidUpdate(
    _prevProps: any,
    prevState: {
      cards: CompareCredit.FormattedCard[]
      comparisonCartCategoryBanner: CompareCredit.FormattedCard[]
      clientLocation: { country: string | undefined; state: string | undefined }
      gaSessionInfo: {
        ga_session_id: string | undefined
        ga_session_number: string | undefined
      }
    },
  ) {
    try {
      if (prevState.cards !== this.state.cards) {
        ls.setItem('comparisonCart', {
          version: this.state.cartVersion,
          cardIds: this.state.cards.map((card) => card.slug),
        })
      }

      if (
        prevState.comparisonCartCategoryBanner !==
        this.state.comparisonCartCategoryBanner
      ) {
        ls.setItem('comparisonCartCategoryBanner', {
          version: this.state.cartCategoryBannerVersion,
          cardIds: this.state.comparisonCartCategoryBanner.map(
            (card) => card.slug,
          ),
        })
      }
    } catch (error) {
      const err = checkErrorInstance(error)

      Sentry.captureException(err)
    }
  }

  // update comparison cart in state
  updateComparisonCart = (
    action: 'add' | 'remove' | 'reset' | 'replace',
    cards?: CompareCredit.FormattedCard[],
  ) => {
    try {
      const pickSlugs = (cards?: CompareCredit.FormattedCard[]) =>
        (cards || []).map((card) => card.slug)
      const slugsCurrentlyInCart = pickSlugs(this.state.cards)

      let filteredCards: CompareCredit.FormattedCard[]

      switch (action) {
        case 'add':
          filteredCards = (cards || []).filter(
            (card) => !slugsCurrentlyInCart.includes(card.slug),
          )
          break
        case 'remove':
          filteredCards = (cards || []).filter((card) =>
            slugsCurrentlyInCart.includes(card.slug),
          )
          break
        case 'reset':
          filteredCards = this.state.cards
          break
        default:
          filteredCards = []
      }

      if (action !== 'replace') {
        const segmentEventHandler =
          action === 'add' ? addProductToCart : removeProductFromCart
        filteredCards.forEach((card) =>
          segmentEventHandler({
            brand: card.issuer.slug.current,
            name: card.name,
            sku: card.slug,
          }),
        )
      }

      switch (action) {
        case 'add':
          this.setState({
            ...this.state,
            cards: [...this.state.cards, ...filteredCards],
          })
          break
        case 'remove':
          /* eslint-disable no-case-declarations */
          const slugsToRemove = pickSlugs(filteredCards)
          const newCart = this.state.cards.filter(
            (card: { slug: any }) => !slugsToRemove.includes(card.slug),
          )
          /* eslint-enable no-case-declarations */
          this.setState({ cards: newCart })
          break
        case 'reset':
          this.setState({ cards: [] })
          break
        case 'replace':
          this.setState({ cards })
          break
        default:
          return
      }
    } catch (error) {
      const err = checkErrorInstance(error)

      Sentry.captureException(err)
    }
  }

  // update comparisonCartCategoryBanner cart in state
  updateComparisonCartCategoryBanner = (
    action: 'add' | 'reset',
    cards?: CompareCredit.FormattedCard[],
  ) => {
    try {
      const pickSlugs = (cards?: CompareCredit.FormattedCard[]) =>
        (cards || []).map((card) => card.slug)
      const slugsCurrentlyInCart = pickSlugs(
        this.state.comparisonCartCategoryBanner,
      )

      let filteredCards: CompareCredit.FormattedCard[]

      switch (action) {
        case 'add':
          filteredCards = (cards || []).filter(
            (card) => !slugsCurrentlyInCart.includes(card.slug),
          )
          break
        case 'reset':
          filteredCards = this.state.comparisonCartCategoryBanner
          break
        default:
          filteredCards = []
      }

      switch (action) {
        case 'add':
          this.setState({
            ...this.state,
            comparisonCartCategoryBanner: [
              ...this.state.comparisonCartCategoryBanner,
              ...filteredCards,
            ],
          })
          break
        case 'reset':
          this.setState({ comparisonCartCategoryBanner: [] })
          break
        default:
          return
      }
    } catch (error) {
      const err = checkErrorInstance(error)

      Sentry.captureException(err)
    }
  }

  getUserId() {
    if (this.state.anonymousId) {
      return this.state.anonymousId
    } else if (typeof window !== 'undefined') {
      return window?.analytics?.user?.()?.anonymousId?.()
    } else {
      return undefined
    }
  }

  renderDnsPreconnectLinks = () => {
    // https://developer.chrome.com/docs/lighthouse/performance/uses-rel-preconnect/
    const preconnectOrigins = [
      'https://data-cdn.comparecredit.com',
      'https://o1025464.ingest.sentry.io',
      'https://featuregates.org',
      'https://events.statsigapi.net',
    ]
    return preconnectOrigins.map((origin) => (
      <React.Fragment key={origin}>
        <link rel="preconnect" href={origin} />
        <link rel="dns-prefetch" href={origin} />
      </React.Fragment>
    ))
  }

  render() {
    const { Component, pageProps } = this.props
    const userID = this.getUserId()

    return (
      <>
        <Head>
          <title>Compare Credit</title>
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <Script
            defer
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            strategy="afterInteractive"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
          {process.env.DEPLOYMENT_TARGET === 'production' && (
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
               <img src="https://sb.scorecardresearch.com/p?c1=2&c2=33669755&cv=2.0&cj=1" />
                `,
              }}
            />
          )}
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
            key="viewport"
          />
          {this.renderDnsPreconnectLinks()}
        </Head>
        <Segment />

        {process.env.DEPLOYMENT_TARGET === 'production' && (
          <Script
            id="jsonld"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{"@context": "http://www.schema.org","@id": "https://www.comparecredit.com/#organization","@type": "Organization","name": "Compare Credit","url": "https://www.comparecredit.com/","sameAs": ["https://www.facebook.com/CompareCredit365/","https://www.linkedin.com/company/comparecredit365/about/","https://twitter.com/Compare__Credit"],"logo": "https://www.comparecredit.com/_next/image?url=%2Fstatic%2Flogo.svg&w=3840&q=75","description": "Compare the best offers on credit cards, personal loans, and more at CompareCredit.com. Find the best option for you and apply securely online today to start saving!"}`,
            }}
          />
        )}

        {/* Zeta Global Universal Pixel code 2023.01 */}

        {/* <Script
          async
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `function zync_call() {
                var z = document.createElement("script");
                var zmpID="comparecredit";
                var cache_buster="{cache_buster}";

                var z_src = "https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=3dc8983d040b362dfa97fd4f3ed3aa7a&k=comparecredit-pixel-0785&zmpID="+zmpID+"&cache_buster="+cache_buster;
                z.setAttribute("src", z_src);
                document.body.appendChild(z);
            }

            if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
                zync_call();
            } else {
                window.addEventListener("DOMContentLoaded", function(){
                    zync_call();
                });
            }`,
          }}
        /> */}

        {/* END Zeta Global code */}

        {this.state.clientLocation.status === 'done' &&
          !this.state.statsigClientIsLoading && (
            <StatsigProvider client={this.state.statsigClient}>
              <UserContext.Provider
                value={{
                  comparisonCart: this.state.cards,
                  comparisonCartCategoryBanner:
                    this.state.comparisonCartCategoryBanner,
                  recommendedCard: this.state.recommendedCard,
                  updateComparisonCart: this.updateComparisonCart,
                  updateComparisonCartCategoryBanner:
                    this.updateComparisonCartCategoryBanner,
                  clientLocation: this.state.clientLocation,
                  gaSessionInfo: this.state.gaSessionInfo,
                  anonymousId: userID || null,
                  navUpsellCard: this.state.navUpsellCard,
                  entryQuery: this.state?.url?.entry?.query || null,
                  search: this.state?.url?.entry?.search,
                }}
              >
                <ModalLeaveBehindContextProvider>
                  <SortSelectContextProvider>
                    <main
                      className={`${InriaSerif.variable} ${OpenSans.variable} ${DmSerif.variable} font-sans`}
                    >
                      <Component {...pageProps} />
                    </main>
                  </SortSelectContextProvider>
                </ModalLeaveBehindContextProvider>
              </UserContext.Provider>
            </StatsigProvider>
          )}
        <Analytics />
        <SpeedInsights />
      </>
    )
  }
}

export default withRouter(MyApp)
