import * as React from 'react'
import path from 'lodash/fp/path'
import some from 'lodash/fp/some'
import { isMobileOnly } from 'react-device-detect'
import { v4 as uuid } from 'uuid'
import { useExperiment } from 'utils/use-experiment-wrapper'
import Image from 'next/image'

import { CardPopularTab, UserContext, useAwardsBadgeContext } from '../'
import { CompareCredit as CC } from '../../../types/compare-credit'
import { clickProduct, viewProduct } from '../../clients/segment'
import {
  BottomSection,
  CollapseDetails,
  CustomBadge,
  LeftSection,
  PanelContainer,
  PanelContents,
  TopSection,
} from './sections'
import { CompareCredit } from './types'
import { CreditCardLinkedData } from '../linked-data/card'
import { nextImageHelper } from '../../utils'

// TODO: having some types in this package and others in `packages/types` is a
// bit confusing. Might be worth going with one route or the other and sticking
// to it. One thought is having the types in `packages/types` be the base types
// that come straight out of Sanity, because those are the types likely to be
// shared across other packages (ETL, etc). Then anything that relates to UI
// would be co-located with the UI.
type Props = {
  categoryId: string
  externalId: string
  entity: CC.FormattedCard | CC.NonPaidCard
  collapsed?: boolean
  propsOrderId?: string
  sortable?: boolean
} & Partial<CompareCredit.CardPanelProps>

export function CardPanel(props: Props) {
  const { collapsed = false } = props
  const {
    categoryId,
    externalId,
    entity,
    view = 'list',
    position,
    arrangementId,
  } = props
  const [cardIsInCart, setCardIsInCart] = React.useState(false)
  const orderId = React.useMemo<string>(
    () => props.propsOrderId || uuid(),
    [props.propsOrderId],
  )
  const [isCollapsed, setCollapsed] = React.useState(
    view === 'list' ? collapsed : false,
  )
  const { comparisonCart, updateComparisonCart } = React.useContext(UserContext)

  const handleCollapseDetails = (collapsed: boolean) => {
    if (!collapsed) {
      clickProduct({
        brand: issuerName,
        name: entity.name,
        sku: entity.slug,
      })
    }
    setCollapsed(collapsed)
  }

  const handleUpdateCart = (action: 'add' | 'remove') => {
    updateComparisonCart(action, [entity as CC.FormattedCard])
  }

  React.useEffect(() => {
    setCardIsInCart(
      some(
        (obj: Partial<CompareCredit.CardPanelTypes>) =>
          obj.slug === entity.slug,
        comparisonCart,
      ),
    )
  }, [comparisonCart.length, entity.name])

  React.useEffect(() => {
    const productDetails = {
      brand: entity.issuer.slug.current,
      id: entity.slug,
      product_id: entity.slug,
      name: entity.name,
      sku: entity.slug,
    }

    if (view === 'details' || view === 'modal') {
      viewProduct(productDetails)
    }
  }, [])

  /* ===== 2025.01: EXP-334 Award Badge | Multi Card Tips Pages ===== */
  const { experiment: configExp334, isLoading: isLoadingExp334 } =
    useExperiment('exp_334_awards_badge_multi_card_tips_page_v1')
  const variationExp334 = configExp334?.value?.name || 'control'

  const { awardsCardsSlugList, tipLabels } = useAwardsBadgeContext()

  /* ===== 2025.02: EXP-360 Award Badge Test Card Treatment | Category v2 ===== */
  const { experiment: configExp360, isLoading: isLoadingExp360 } =
    useExperiment('exp_360_award_badge_category_v2')
  const variationExp360 = configExp360?.value?.name || 'control'
  const isV1 =
    !isLoadingExp360 && variationExp360 === 'v1-badge-and-banner-for-first-card'
  const isV2 =
    !isLoadingExp360 &&
    variationExp360 === 'v2-badge-and-banner-for-all-award-winning-cards'

  const showAwardsBadge = React.useMemo(() => {
    const hasAwardsCard =
      awardsCardsSlugList &&
      Object.keys(awardsCardsSlugList).includes(entity.slug)
    if (!hasAwardsCard) return false

    const showExp334 =
      position === 1 &&
      !tipLabels?.includes('focused-multicard') &&
      !isLoadingExp334 &&
      variationExp334 === 'v1-badge-banner-text'

    const showV1 = position === 1 && isV1

    return showExp334 || showV1 || isV2
  }, [
    position,
    entity.slug,
    JSON.stringify(awardsCardsSlugList),
    tipLabels,
    isLoadingExp334,
    variationExp334,
    isV1,
    isV2,
  ])

  const awardsBadgeTitle = React.useMemo(() => {
    return showAwardsBadge ? awardsCardsSlugList?.[entity.slug] : undefined
  }, [showAwardsBadge, JSON.stringify(awardsCardsSlugList), entity.slug])

  const isDetail = view === 'details'
  const isModalOrDetail = view === 'details' || view === 'modal'
  const issuerName = path(['issuer', 'name'], props)

  // 2024.08: EXP-324 Category Flag
  const { experiment: configExp324, isLoading: isLoadingExp324 } =
    useExperiment('exp_324_category_flag_v2')
  const variationExp324 = configExp324?.value?.name || 'control'

  return (
    <PanelContainer isDetail={isDetail} view={view} isCollapsed={isCollapsed}>
      <>
        {!isLoadingExp324 &&
          variationExp324 == 'v13-popular-tab' &&
          position == 1 && <CardPopularTab />}
        {props.onClose && (
          <button onClick={props.onClose}>
            <img
              alt=""
              aria-label="Close"
              className="cursor-pointer absolute top-0 right-0 / w-8 / mt-1 mr-1 md:mt-4 md:mr-4 / opacity-25 / hover:opacity-75 focus:outline-none / transition-all z-10"
              src="/static/icons/icon-close-circle-black.svg"
            />
          </button>
        )}
        {entity.customBadge && view !== 'details' && (
          <CustomBadge text={entity.customBadge} view={view} />
        )}

        {showAwardsBadge && awardsBadgeTitle && (
          <div className="-mb-8 md:-mb-5 lg:-mb-6 / px-10 md:pl-14 lg:pl-18 xl:pl-20 / md:pr-0 pt-4 / text-center md:text-left">
            <div
              className="inline-block / w-auto relative / mb-3 / md:px-0 /
              uppercase text-primary font-bold text-center tracking-wide leading-snug text-sm lg:text-base"
            >
              <span className="inline-block / absolute -top-[2px] lg:-top-[4px] -left-8 lg:-left-10 / shrink-0 / w-5 lg:w-6">
                <Image
                  width={16}
                  height={16}
                  alt="CompareCredit Awards Ribbon"
                  src="/static/awards/cc-awards-ribbon-small-clr.svg"
                  aria-hidden={true}
                  sizes="100vw"
                  style={nextImageHelper.responsive}
                />
              </span>
              <span>{awardsBadgeTitle}</span>
            </div>
          </div>
        )}
        <PanelContents view={view}>
          <LeftSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            featured={props.featured}
            entity={entity}
            show={view === 'list'}
            comparisonDisabled={props.comparisonDisabled}
            position={position}
            arrangementId={arrangementId}
            view={props.view}
            sortable={view === 'list'}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
          <TopSection
            categoryId={categoryId}
            externalId={externalId}
            orderId={orderId}
            entity={entity}
            featured={isModalOrDetail ? false : props.featured}
            updateCart={handleUpdateCart}
            inCart={cardIsInCart}
            view={view}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
            category={props.category || ''}
            isMobile={isMobileOnly}
            comparisonDisabled={props.comparisonDisabled}
            position={position}
          />
          <BottomSection
            orderId={orderId}
            entity={entity}
            setCollapsed={setCollapsed}
            isCollapsed={view === 'list' ? isCollapsed : false}
            view={view}
          />
        </PanelContents>
        {view === 'list' && (
          <CollapseDetails
            orderId={orderId}
            entity={entity}
            isCollapsed={isCollapsed}
            setCollapsed={handleCollapseDetails}
          />
        )}
        <CreditCardLinkedData card={entity} />
      </>
    </PanelContainer>
  )
}
