import * as React from 'react'
import times from 'lodash/fp/times'
import toString from 'lodash/fp/toString'
import Link from 'next/link'

let popupTimeout: ReturnType<typeof setTimeout>

export function StarRatingEditor(props: {
  disableInput?: boolean
  positionTop?: boolean
  productSlug: string
  stars: number
  themeClass?: string
  themeLeft?: boolean
  themeLight?: boolean
  themeRow?: boolean
}) {
  const {
    disableInput = false,
    positionTop,
    stars,
    themeLeft,
    themeLight,
    themeRow,
  } = props

  const [hoveringOverStars, setHoveringOverStars] = React.useState(false)
  const [hoveringOverPopup, setHoveringOverPopup] = React.useState(false)
  const [starRating] = React.useState(4)

  const createScoreArry = (stars: number) => {
    const [wholeNum, halfNum] = toString(stars).split('.').map(Number)
    return times((x: number) => {
      const idx = x + 1
      if (idx <= wholeNum) return 'full'
      if (idx - wholeNum === 1 && halfNum >= 5) return 'half'
      return 'empty'
    }, 5)
  }

  const handlePopupMouseEnter = () => {
    if (popupTimeout) clearTimeout(popupTimeout)
    setHoveringOverPopup(true)
  }

  const handlePopupMouseLeave = () => {
    popupTimeout = setTimeout(() => {
      setHoveringOverStars(false)
      setHoveringOverPopup(false)
    }, 200)
  }

  return (
    <div
      className={`c-product-rating__container relative z-20 / inline-block / mb-1`}
    >
      <div
        className={`c-product-rating / flex / mx-auto /
        ${positionTop ? 'c-product-rating--top' : ''}
        ${themeLight == true ? 't-light' : ''}
        ${themeLeft == true ? 't-left' : ''}
        ${themeRow == true ? 't-row' : ''}
        items-center justify-center
      `}
      >
        <div
          className={`c-product-rating_editor / relative / flex flex-row / max-w-none`}
          onMouseEnter={() => {
            setHoveringOverStars(!disableInput)
          }}
          onMouseLeave={() => {
            setHoveringOverStars(false)
          }}
        >
          <div className="c-product-rating__score / flex items-end justify-center / -mt-1">
            <span className="c-product-editor__score-main / inline-block / text-[32px] font-bold text-primary leading-tight">
              {stars ? (
                stars.toFixed(1)
              ) : (
                <span className="text-slate-400 font-normal">N/A</span>
              )}
            </span>
          </div>
          <div className="c-product-rating__stars-wrap / pl-2.5 / ">
            <ul className="c-product-rating__stars / flex">
              {createScoreArry(stars)
                .map((star: string, i: number) => {
                  if (hoveringOverStars || hoveringOverPopup) {
                    return i <= starRating ? 'full' : 'empty'
                  } else {
                    return star
                  }
                })
                .map((star: string, i: number) => {
                  const getStarClass = (star: string) => {
                    switch (star) {
                      case 'full':
                        return 'c-product-rating__star full'
                      case 'half':
                        return 'c-product-rating__star half'
                      case 'empty':
                        return 'c-product-rating__star'
                    }
                  }
                  return (
                    <li
                      className={`c-product-rating__item flex w-6 h-6`}
                      key={i}
                    >
                      <span
                        className={`${getStarClass(
                          star,
                        )} inline-block / w-full / bg-cover bg-transparent bg-no-repeat`}
                        aria-label={`rate card ${i + 1} star`}
                      />
                    </li>
                  )
                })}
            </ul>
            <div className="c-product-rating__text / text-fs12 text-slate-600 text-left leading-[1.1]">
              <span className="c-product-editor__score-main-2 / mr-1 / font-bold / hidden">
                {stars ? (
                  stars.toFixed(1)
                ) : (
                  <span className="text-slate-400 font-normal">N/A</span>
                )}
              </span>
              CompareCredit Score
            </div>
          </div>

          <div
            className={`c-rating-popup / c-arrow-css / absolute z-10 text-center / bg-white shadow-xl rounded ${
              hoveringOverPopup || hoveringOverStars ? 'block' : 'hidden'
            }
            ${positionTop ? 'c-rating-popup--top' : ''}
            ${positionTop ? 'c-arrow-css--south' : ''}
            `}
            aria-hidden={!hoveringOverPopup && !hoveringOverStars}
            onMouseEnter={handlePopupMouseEnter}
            onMouseLeave={handlePopupMouseLeave}
          >
            <p className="px-2 py-1.5 / text-sm text-gray-700 / border-b border-gray-200">
              Rated{' '}
              <b className="text-primary">
                {stars ? (
                  stars.toFixed(1)
                ) : (
                  <span className="text-slate-400 font-normal">N/A</span>
                )}
                <span className="font-normal text-[12px] text-slate-700">
                  /5
                </span>
              </b>{' '}
              stars by our content team
            </p>

            <div className="px-2 py-1">
              <div className="mt-1.5 / pt-0.5 pb-1 px-2">
                <p className="text-left text-fs12 text-gray-600 italic">
                  CompareCredit{' '}
                  <Link
                    href={`/ratings`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    star ratings
                  </Link>{' '}
                  are determined by reviewing a variety of factors including
                  bonus offers, rewards rates, and card benefits.{' '}
                  <Link
                    href={`/partners`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-primary-bright transition-all hover:text-primary-mid underline"
                  >
                    Our partners
                  </Link>
                  {` `}
                  do not influence how we rate the cards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        /* ===== T-ROW ===== */
        .t-row .c-product-rating__score {
          display: none;
        }
        .t-row .c-product-editor__score-main-2 {
          display: inline-block;
        }
        .t-row .c-product-rating__stars-wrap {
          padding-left: 0;
        }
        .t-row .c-product-rating__stars {
          justify-content: center;
          align-items: center;
        }
        .t-row .c-product-rating__text {
          text-align: center;
        }

        /* ===== T-LIGHT ===== */
        .t-light .c-product-rating__text {
          color: #ffffff;
        }
        .t-light .c-product-editor__score-main-2 {
          color: #d0eafd;
        }

        /* ===== T-LEFT ===== */
        .t-left .c-product-rating__stars {
          justify-content: start;
          align-items: start;
        }
        .t-left .c-product-rating__text {
          text-align: left;
        }
      `}</style>
    </div>
  )
}
