import React from 'react'
import Link from 'next/link'
import isEmpty from 'lodash/fp/isEmpty'
import { isMobileOnly } from 'react-device-detect'
import propOr from 'lodash/fp/propOr'
import { PortableText } from '@portabletext/react'

import {
  CallToApply,
  CardRecommendedCredit,
  LinkToRedirect,
  UserContext,
} from '../../'
import { CardComparisonTableVerticalSkeleton } from './loading-skeleton-card-comparison-table-vertical'
import { CardTile } from './card-tile'
import { firstIsVowel } from '../../../utils'
import { getFeaturesToRender } from '../../../utils/getFeaturesToRender'
import { clickRatesAndFees } from '../../../clients/segment'
import { CompareCredit } from '../../../../types/compare-credit'

type CardAttributeKey = keyof CompareCredit.FormattedCard

export const CardComparisonTableVertical = (data: {
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  const CardComparisonTableVerticalRender = (props: {
    value: {
      cardTips: CompareCredit.CardTip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      title: string
      subtitle: string
      pinnedCardTips?: CompareCredit.PinnedProduct[]
      cardOrderIds: Record<string, string>
    }
  }) => {
    const { categoryTag, tag, title, subtitle } = props.value
    const { cardOrderIds, referencedCards } = data
    const categoryId = categoryTag ? categoryTag.id : '00'
    const externalId = tag ? tag.id : '00'
    const { clientLocation } = React.useContext(UserContext)

    // 1. CARDS
    const cardSlugs = props.value.cardTips.map((cardTip) => cardTip.card.slug)
    const cards = isEmpty(referencedCards)
      ? []
      : (cardSlugs
          .map((slug) => propOr(null, slug, referencedCards))
          .filter((x) => x) as CompareCredit.FormattedCard[])

    // 2. RIBBON TEXT AND COLOR
    const cardRibbonText = props.value.cardTips.map(
      (cardTip) => cardTip.ribbonText,
    )
    const cardRibbonColor = props.value.cardTips.map(
      (cardTip) => cardTip.ribbonColor,
    )

    return (
      <>
        <section className="c-compare-v / mt-4 mb-8 leading-snug">
          <div className="c-compare-v__header / text-center">
            {title && (
              <p className="c-compare-v__title / mb-0.5 / font-serif text-2xl md:text-3xl font-bold text-primary leading-snug">
                {title}
              </p>
            )}

            {subtitle && (
              <p className="c-compare-v__subtitle / mb-0.5 sm:mb-2 / text-lg font-semibold text-slate-600">
                {subtitle}
              </p>
            )}

            <p className="c-compare-v__helper-text / mb-1.5 / w-full sm:order-1 font-bold text-sm text-gray-700 text-center / sm:hidden">
              Viewing {cards.length} card
              {cards.length > 1 ? 's' : ''}
              {cards.length > 2 ? (
                <span className="inline-block / ml-1.5 / font-normal text-xs text-gray-600 tracking-wider uppercase">
                  (Scroll right to view)
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
          {isEmpty(cards) && (
            <CardComparisonTableVerticalSkeleton
              n={
                cards.length === 1
                  ? 1
                  : cards.length === 2
                  ? 2
                  : cards.length > 2
                  ? 3
                  : 4
              }
            />
          )}
          <div
            className={`c-compare-v__wrap / relative z-1 / ${
              cards.length === 1
                ? 't-compare-v-1'
                : cards.length === 2
                ? 't-compare-v-2'
                : cards.length === 3
                ? 't-compare-v-3'
                : 't-compare-v-4'
            } `}
          >
            {cards && (
              <div className="c-compare-v__container / w-full / shadow-lg rounded-sm">
                <div className="c-compare-v__inner / text-sm text-center leading-snug">
                  <div className="c-compare-v__row c-compare-v__row-header flex items-stretch justify-start / w-full">
                    {cards.map((card, i) => (
                      <div
                        key={i}
                        className={`c-compare-v__cell px-4 py-3 ${
                          cardRibbonColor[i] === 'gold'
                            ? 'border-r-2 border-l-2 border-t-2 border-tetriary border-solid'
                            : ''
                        }`}
                      >
                        <CardTile
                          key={i}
                          card={card}
                          orderId={cardOrderIds[i]}
                          categoryId={categoryId}
                          externalId={externalId}
                          referencedCards={referencedCards}
                          ribbonText={cardRibbonText[i]}
                          ribbonColor={cardRibbonColor[i]}
                          theme={
                            cards.length === 1
                              ? 't-compare-v-1'
                              : cards.length === 2
                              ? 't-compare-v-2'
                              : cards.length === 3
                              ? 't-compare-v-3'
                              : 't-compare-v-4'
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {cardOrderIds && (
                    <div
                      className={`c-compare-v__row / border-b-2 border-slate-400`}
                    >
                      {cards.map((card, i) => (
                        <div
                          key={i}
                          className={`c-compare-v__cell px-4 pb-3 ${
                            cardRibbonColor[i] === 'gold'
                              ? 'border-r-2 border-l-2 border-tetriary border-solid'
                              : ''
                          }`}
                        >
                          <div
                            className={
                              card.phoneNumber &&
                              isMobileOnly &&
                              clientLocation.country === 'US'
                                ? 'mb-1'
                                : 'mb-1'
                            }
                          >
                            {card._type === 'card' ? (
                              <LinkToRedirect
                                externalId={externalId}
                                linkParams={card.applyNowLinkParameters}
                                orderId={cardOrderIds[i]}
                                slug={card.slug}
                              >
                                <div className="c-btn c-btn--primary / w-full max-w-xs / py-2 xs:py-2 / text-base xs:text-lg rounded-sm">
                                  Apply Now
                                  <span className="c-btn__icon / ml-2 hidden xs:inline-block">
                                    <img
                                      className="w-4"
                                      alt=""
                                      src="/static/icons/icon-lock-round-w.svg"
                                    />
                                  </span>
                                </div>
                              </LinkToRedirect>
                            ) : (
                              <Link href={card.moreInfoLink}>
                                <p className="c-btn c-btn--secondary / w-full max-w-xs / py-2 xs:py-2 / text-base xs:text-lg rounded-sm">
                                  Learn More
                                </p>
                              </Link>
                            )}
                          </div>
                          {card._type === 'card' &&
                            card.phoneNumber &&
                            isMobileOnly &&
                            clientLocation?.country === 'US' && (
                              <div className="w-full mt-2">
                                <CallToApply
                                  classes="text-lg md:text-base lg:text-lg / mb-4"
                                  entity={card}
                                  numOnly
                                />
                                <p className="w-full text-xs text-gray-600 / text-center / -mt-2 -mb-2 md:mb-2 mb-2 / md:self-start w-full leading-4">
                                  with{' '}
                                  {firstIsVowel(card.issuer.name) ? 'an' : 'a'}{' '}
                                  {card.issuer.name} representative
                                </p>
                              </div>
                            )}
                          {card.ratesAndFees && card.ratesAndFees && (
                            <div className="w-full / text-center h-6">
                              {card.ratesAndFees &&
                                card.ratesAndFees !== 'N/A' && (
                                  <a
                                    className="text-primary-bright text-xs"
                                    href={card.ratesAndFees}
                                    onClick={() => {
                                      clickRatesAndFees({
                                        applyNowLink: card.applyNowLink,
                                        component:
                                          'Card Comparison Table Vertical',
                                        name: card.name,
                                        url: window.location.href,
                                      })
                                    }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Rates &amp; Fees
                                  </a>
                                )}
                              {card.issuer.slug.current ===
                                'american-express' && (
                                <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                                  Terms Apply
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="c-compare-v__row / bg-slate-50">
                    {cards.map((card, i) => {
                      const featuresToRender = getFeaturesToRender(
                        card,
                        categoryId, // check with Alex if we want this to be the internal or exeternal tag id
                      )
                      return (
                        <div
                          key={i}
                          className={`c-compare-v__cell px-4 py-3 ${
                            cardRibbonColor[i] === 'gold'
                              ? 'border-r-2 border-l-2 border-tetriary border-solid'
                              : ''
                          }`}
                        >
                          {featuresToRender.map((feature, i) => {
                            const featureItem = i === 0 ? false : true

                            return featureItem ? null : (
                              <div key={i}>
                                <p
                                  className="mb-1 font-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: feature.Title,
                                  }}
                                />
                                {feature._type === 'custom' ? (
                                  <div>{feature.value}</div>
                                ) : (
                                  <div>
                                    <PortableText
                                      value={feature.Description as any}
                                      components={{
                                        block: {
                                          span: ({ children }) => (
                                            <span>{children}</span>
                                          ),
                                        },
                                        types: {
                                          cardAttribute: ({
                                            value,
                                          }: {
                                            value: {
                                              attribute: CardAttributeKey
                                            }
                                          }) => {
                                            const { attribute } = value
                                            return (
                                              <span>
                                                {card[attribute] as string}
                                              </span>
                                            )
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  <div className="c-compare-v__row">
                    {cards.map((card, i) => {
                      const featuresToRender = getFeaturesToRender(
                        card,
                        categoryId, // same here, check if we want it to be the internal or external tag id
                      )
                      return (
                        <div
                          key={i}
                          className={`c-compare-v__cell px-4 py-3 ${
                            cardRibbonColor[i] === 'gold'
                              ? 'border-r-2 border-l-2 border-tetriary border-solid'
                              : ''
                          }`}
                        >
                          {featuresToRender.map((feature, i) => {
                            const featureItem = i === 1 ? false : true

                            return featureItem ? null : (
                              <div key={i}>
                                <div
                                  className="mb-1 font-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: feature.Title,
                                  }}
                                />
                                {feature._type === 'custom' ? (
                                  <div>{feature.value}</div>
                                ) : (
                                  <div>
                                    <PortableText
                                      value={feature.Description as any}
                                      components={{
                                        block: {
                                          span: ({ children }) => (
                                            <span>{children}</span>
                                          ),
                                        },
                                        types: {
                                          cardAttribute: ({
                                            value,
                                          }: {
                                            value: {
                                              attribute: CardAttributeKey
                                            }
                                          }) => {
                                            const { attribute } = value
                                            return (
                                              <span>
                                                {card[attribute] as string}
                                              </span>
                                            )
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  <div className="c-compare-v__row / bg-slate-50">
                    {cards.map((card, i) => {
                      const featuresToRender = getFeaturesToRender(
                        card,
                        categoryId, // same here, check if we want it to be the internal or external tag id
                      )
                      return (
                        <div
                          key={i}
                          className={`c-compare-v__cell px-4 py-3 ${
                            cardRibbonColor[i] === 'gold'
                              ? 'border-r-2 border-l-2 border-tetriary border-solid'
                              : ''
                          }`}
                        >
                          {featuresToRender.map((feature, i) => {
                            const featureItem = i === 2 ? false : true

                            return featureItem ? null : (
                              <div key={i}>
                                <div
                                  className="mb-1 font-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: feature.Title,
                                  }}
                                />
                                {feature._type === 'custom' ? (
                                  <div>{feature.value}</div>
                                ) : (
                                  <div>
                                    <PortableText
                                      value={feature.Description as any}
                                      components={{
                                        block: {
                                          span: ({ children }) => (
                                            <span>{children}</span>
                                          ),
                                        },
                                        types: {
                                          cardAttribute: ({
                                            value,
                                          }: {
                                            value: {
                                              attribute: CardAttributeKey
                                            }
                                          }) => {
                                            const { attribute } = value
                                            return (
                                              <span>
                                                {card[attribute] as string}
                                              </span>
                                            )
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  <div className="c-compare-v__row">
                    {cards.map((card, i) => (
                      <div
                        key={i}
                        className={`c-compare-v__cell px-4 py-3 ${
                          cardRibbonColor[i] === 'gold'
                            ? 'border-r-2 border-l-2 border-tetriary border-solid'
                            : ''
                        }`}
                      >
                        <p className="mb-1 font-bold">Annual Fee</p>
                        <p className="mb-0">{card.feeAnnual || 'N/A'}</p>
                      </div>
                    ))}
                  </div>
                  <div className="c-compare-v__row c-compare-v__row--rec-credit / bg-slate-50">
                    {cards.map((card, i) => (
                      <div
                        key={i}
                        className={`c-compare-v__cell / flex justify-center items-center / px-4 py-2 ${
                          cardRibbonColor[i] === 'gold'
                            ? 'border-r-2 border-l-2 border-tetriary border-solid'
                            : ''
                        }`}
                      >
                        <CardRecommendedCredit
                          card={card}
                          style="mx-auto"
                          disclosureStyling="t-align-top t-slim t-compare-v"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="c-compare-v__row / c-compare-v__row--more-details">
                    {cards.map((card, i) => (
                      <div
                        key={i}
                        className={`c-compare-v__cell px-4 py-3.5 ${
                          cardRibbonColor[i] === 'gold'
                            ? 'border-r-2 border-l-2 border-b-2 border-tetriary border-solid'
                            : ''
                        }`}
                      >
                        <Link
                          href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                          className="text-sm text-primary-bright hover:text-primary-mid"
                          target={isMobileOnly ? '_blank' : ''}
                        >
                          More Details
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <style jsx>{`
            .c-compare-v__row {
              width: 100%;
              clear: left;
              display: table;
              table-layout: fixed;
            }
            .c-compare-v__cell {
              display: table-cell;
              border-right: 1px solid #e5e5e5;
            }
            .c-compare-v__cell:last-child {
              border-right: 0;
            }
            .c-compare-v__cell.border-r-2.border-tetriary {
              border-right: 2px solid #fdca41;
            }

            /*===== THEME =====*/
            .t-compare-v-1 .c-compare-v__cell {
              width: 100%;
            }

            .t-compare-v-2 .c-compare-v__cell {
              width: 50%;
            }
            @media (min-width: 480px) {
              .t-compare-v-2 .c-btn {
                width: 66.666666%;
              }
            }

            .t-compare-v-3 .c-compare-v__cell,
            .t-compare-v-4 .c-compare-v__cell {
              width: 200px;
            }
            .t-compare-v-3 .c-compare-v__inner,
            .t-compare-v-4 .c-compare-v__inner {
              overflow: scroll;
            }
            .t-compare-v-4 .c-btn {
              font-size: 16px;
            }
            .t-compare-v-3 .c-compare-v-card__name,
            .t-compare-v-4 .c-compare-v-card__name {
              font-size: 14px;
            }

            @media (min-width: 768px) {
              .t-compare-v-3 .c-compare-v__cell {
                width: 33.333333%;
              }
            }
          `}</style>
        </section>
      </>
    )
  }
  return CardComparisonTableVerticalRender
}
